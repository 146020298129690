<template>
  <div
    id="tooltipMain"
    ref="tooltip"
    class="position-absolute card"
    style="width: 16rem; z-index: 50"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <div
      v-if="singleActivity && activity && (!activity.brief.isConfidential || user.isAdminUser)"
      class="card-body bg-light pb-0 lh-1 font-display"
    >
      <div v-if="tooltipType === 'calendar'" class="bg-info text-white py-1 px-3">
        {{ activity.channel ? activity.channel.label : "No Channel" }}
      </div>

      <div class="bg-info text-white fs-5 pb-2 px-3">{{ formattedStartDate }} - {{ formattedEndDate }}</div>

      <ul class="list-group list-group-flush bg-light">
        <!--ps-3 pe-5 mt-2 -->
        <li class="list-group-item bg-light py-1">
          <span class="fw-bold">Requested by</span>:
          <span v-if="activity.brief">{{ activity.brief.createdBy.name }}</span>
        </li>
        <li class="list-group-item bg-light py-1">
          <span class="fw-bold">Owner</span>: <span v-if="activity.owner">{{ activity.owner.name }}</span
          ><span v-else>-</span>
        </li>
        <li class="list-group-item bg-light py-1">
          <span class="fw-bold">Status</span>: <span v-if="activity.status">{{ activity.status.label }}</span>
        </li>
        <!-- <li class="list-group-item bg-light" >
          <span class="fw-bold">Campaign</span>:
          <span v-if="activity.brief">{{
            activity.brief && activity.brief.program ? activity.brief.program.label : "No Program"
          }}</span>
        </li> -->
        <li class="list-group-item bg-light py-1">
          <span class="fw-bold">Location</span>: <span v-if="activity.location">{{ activity.location.label }}</span
          ><span v-else>All</span>
        </li>
        <li class="list-group-item bg-light py-1">
          <span class="fw-bold">Activities in brief</span>: <span>{{ activityCount }}</span>
        </li>
        <li v-if="activity.brief && activity.brief.isConfidential" class="mt-2 list-group-item bg-light">
          <span class="fw-bold">Highly confidential</span>
        </li>
      </ul>

      <!-- <div class="btn-group text-end pt-2" role="group">
        <button class="btn btn-sm btn-outline-secondary" title="Highlight" @click="$emit('highlight', activity.brief)">
          <BaseIcon class="text-lg" :name="['far', 'lightbulb']" />
        </button>
        <button class="btn btn-sm btn-outline-secondary" title="Lowlight" @click="$emit('lowlight', activity.brief)">
          <BaseIcon class="text-lg" :name="['fas', 'lightbulb']" />
        </button>
        <button
          class="btn btn-sm btn-outline-secondary"
          title="Show only this brief"
          @click="$emit('showOnly', activity.brief)"
        >
          <BaseIcon class="text-lg" :name="['fas', 'stream']" />
        </button>
        <button class="btn btn-sm btn-outline-secondary" title="Reset view" @click="$emit('resetView')">
          <BaseIcon class="text-lg" :name="['fas', 'undo']" />
        </button>
      </div> -->
    </div>

    <div
      v-else-if="singleActivity && activity.brief.isConfidential && !user.isAdminUser"
      class="card-body bg-light pb-3"
    >
      <div class="bg-info text-white px-3 py-1">
        <span>Confidential</span>
        <span v-if="activity.activityCount">({{ activity.activityCount }})</span>
      </div>
      <div class="bg-muted px-3 py-1">({{ formattedStartDate }} - {{ formattedEndDate }})</div>

      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <span class="fw-bold">Confidential</span>
        </li>
        <li class="list-group-item">
          <span class="fw-bold">Owner</span>: <span v-if="activity.owner">{{ activity.owner.name }}</span
          ><span v-else>-</span>
        </li>
      </ul>
    </div>

    <div v-else-if="!singleActivity" class="bg-muted card-body">
      <div v-if="tooltipType === 'calendar'" class="bg-info text-white px-3 py-1">
        <span>{{ activity[0].channel ? activity[0].channel.label : $t("calendar.noChannel") }}</span>
        <span v-if="activity.activityCount">({{ activity.activityCount }})</span>
      </div>
      <div v-if="activity.activityCount" class="fw-bold mt-2 pl-3">
        Multiple activities ({{ activity.activityCount }})
      </div>
      <ul class="mt-2">
        <li v-for="a in activity" :key="a.id" class="text-xs">
          <BaseLink
            v-if="a.brief"
            :to="{ name: 'briefs.edit', params: { id: a.extendedProps.brief.id } }"
            class="w-100 py-1 px-3"
          >
            {{ a.brief.title }}
          </BaseLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<!-- Usage: <Tooltip
        v-if="tooltipShown"
        :activity="activityInTooltip"
        :style="'top: ' + tooltipTop + 'px; left: ' + tooltipLeft + 'px;'"
        tooltip-type="calendar"
        :loading-highlight="tooltipLoading"
        @mouseenter="cancelHideTooltip"
        @mouseleave="onCalendarMouseLeave"
        @highlight="highlightBriefActivities"
        @lowlight="lowlightBriefActivities"
        @showOnly="showOnlyBriefActivities"
        @resetView="resetLowHighlight"
      /> -->

<script>
import { format, parseISO } from "date-fns";

import { GetActivitiesCountOfBrief } from "@gql/brief";
import { LocalGetSelf } from "@gql/user";

export default {
  name: "CalendarTooltip",
  props: {
    activity: {
      type: [Object, Array],
      required: true,
    },
    tooltipType: {
      type: String,
      default: "calendar",
    },
    loadingHighlight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    singleActivity() {
      return typeof this.activity === "object" && !this.activity.activityCount;
    },
    formattedEndDate() {
      return this.activity.endDate ? format(parseISO(this.activity.endDate), "dd MMM") : null;
    },
    formattedStartDate() {
      return this.activity.startDate ? format(parseISO(this.activity.startDate), "dd MMM") : null;
    },
  },
  apollo: {
    user: LocalGetSelf,
    activityCount: {
      query: GetActivitiesCountOfBrief,
      variables() {
        return {
          companyId: this.user.company.id,
          briefId: this.singleActivity && this.activity.brief ? this.activity.brief.id : "",
        };
      },
      skip() {
        return !this.singleActivity || !this.activity.brief || !this.activity.brief.id || !this.user;
      },
      fetchPolicy: "network-only",
      update: (data) => data.activityCount,
    },
  },
  updated() {
    // Set position
    document.querySelector("#tooltipMain").style.marginTop =
      "-" + (document.querySelector("#tooltipMain").clientHeight + 20) + "px";
  },
  mounted() {
    // Set position
    document.querySelector("#tooltipMain").style.marginTop =
      "-" + (document.querySelector("#tooltipMain").clientHeight + 20) + "px";
  },
};
</script>

<style scoped lang="scss">
.card-body {
  padding: 0rem;
  font-size: 0.875rem;
}
</style>
