<template>
  <Layout>
    <div id="printheader" class="container-fluid mb-4 d-none">
      <div class="py-4 bg-primary justify-content-around text-white row">
        <div class="col align-content-center" @click="$router.push({ name: 'home' })">
          <h1 class="application-logo">Outcomms</h1>
          <span class="ms-3">Outcomms</span>
        </div>
        <div class="pe-3 text-right text-light">
          Summary created by {{ user.name }}.<br />All content strictly private and confidential.
        </div>
      </div>
    </div>

    <!-- Loading spinner -->
    <div
      v-if="$apollo.loading"
      class="position-fixed end-0 w-100 justify-content-center"
      style="background: rgba(255, 255, 255, 0.8); height: 100vh; z-index: 1050"
    >
      <div class="text-center col pt-5 mt-5">
        <BaseSpinner />
        <p class="d-block lead fs-4 mt-5">The calendar is loading</p>
      </div>
    </div>

    <div class="container-fluid">
      <div id="calendartopbar" class="row">
        <div class="col-4 col-md-3 col-lg-6 col-xl-6 col-xxl-7 align-items-center d-flex">
          <a class="link-orange" @click="showFilterPanel">
            <svg
              viewBox="0 0 100 100"
              width="15"
              height="15"
              fill="#e26c49"
              class="link-orange"
              style="margin-right: 15px"
            >
              <rect width="100" height="20"></rect>
              <rect y="30" width="100" height="20"></rect>
              <rect y="60" width="100" height="20"></rect>
            </svg>
            Show filters
          </a>
        </div>
        <div class="col-8 col-md-7 col-lg-4 col-xl-4 col-xxl-3 text-end">
          <form class="form-inline justify-content-end" @submit.prevent="filterTitle">
            <BaseInput
              v-model="filter.brief.title.contains"
              placeholder="Brief title..."
              type="text"
              name="search"
              icon-classes="text-muted"
              :icon="['fas', 'search']"
              @input="debouncedFilterTitle"
              @keyup="debouncedFilterTitle"
            />
          </form>
        </div>
        <div class="col-12 col-md-2 col-xl-2 col-xxl-2 text-end">
          <a
            v-if="user.isTeamUser"
            class="btn btn-success text-center"
            role="button"
            @click="$router.push({ name: 'briefs.new' })"
            >Create new</a
          >
        </div>
      </div>

      <hr class="dotted-spacer" />

      <div class="row justify-content-start">
        <div class="col">
          <div class="row row-cols-lg-auto g-1 align-items-center justify-content-start">
            <div class="col-12">
              <div id="navButtons" class="button-group">
                <button
                  type="button"
                  class="btn fs-6 btn-sm me-1 text-white btn-primary"
                  aria-label="prev"
                  @click="goToPreviousMonth"
                >
                  <span class="fc-icon fc-icon-chevron-left"></span>
                </button>
                <button
                  type="button"
                  class="btn fs-6 btn-sm me-3 text-white btn-primary"
                  aria-label="next"
                  @click="goToNextMonth"
                >
                  <span class="fc-icon fc-icon-chevron-right"></span>
                </button>
              </div>
            </div>
            <div id="todayButton" class="col-12">
              <button id="todayButton" type="button" class="btn text-white btn-primary me-3" @click="gotoToday">
                today
              </button>
            </div>
            <div class="col-12">
              <div v-click-outside="hideMonthDropdown" class="dropdown">
                <BaseButton
                  class="lead text-muted inline align-middle outline-none btn-link dropdown-toggle"
                  :class="{ show: showMonthDropdown }"
                  @click="showMonthDropdown = !showMonthDropdown"
                >
                  {{ currentMonth.name }}
                </BaseButton>

                <ul v-if="showMonthDropdown" class="dropdown-menu" :class="{ show: showMonthDropdown }">
                  <li v-for="month in monthList" :key="month.value">
                    <BaseButton
                      class="dropdown-item btn-link my-1 lead text-start link-muted"
                      @click="
                        showMonthDropdown = false;
                        jumpToMonth(month);
                      "
                    >
                      {{ month.name }}
                    </BaseButton>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col mt-2">
          <FullCalendar ref="fullCalendar" :options="calendarOptions" />
        </div>
      </div>

      <!-- Channel colour legend -->
      <div class="mt-4 row">
        <div class="col col-lg-9 col-xl-9">
          <h5 class="lead text-muted">Legend:</h5>
          <div class="row">
            <div v-for="channel in legendChannels" :key="channel.id" class="col-4 col-lg-3 col-xl-2">
              <div class="row form-group mt-2 ps-3 cursor-pointer" :class="{'lowlighted': focusedChannel && channel.id !== focusedChannel.id}"
                @mouseenter="debounce(highlightChannel(channel), 1500)"
                @mouseleave="resetLowHighlight(false)"
              >
                <!-- @click="highlightChannel(channel, true)" -->
                <span
                  :style="'height: 20px; width: 20px; background:' + channel.color"
                  class="col-4 col-form-label"
                ></span>
                <span class="col-8 ms-2 fw-light text-nowrap">{{ channel.label }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col col-lg-3 col-xl-2">
          <span v-if="activities" class="text-muted">Activities in view: {{ activities.length }}</span>
          <BaseButton id="printButton" class="inline text-muted" @click.prevent="printPage">
            <BaseIcon :name="['fas', 'print']" />
            Print this calendar
          </BaseButton>
        </div>
      </div>

      <Tooltip
        v-if="tooltipShown"
        :activity="activityInTooltip"
        :style="'top: ' + tooltipTop + 'px; left: ' + tooltipLeft + 'px;'"
        tooltip-type="calendar"
        :loading-highlight="tooltipLoading"
        @mouseenter="cancelHideTooltip"
        @mouseleave="onCalendarMouseLeave"
        @highlight="highlightBriefActivities"
        @lowlight="lowlightBriefActivities"
        @showOnly="showOnlyBriefActivities"
        @resetView="resetLowHighlight"
      />

      <slideout-panel><FilterPanel></FilterPanel></slideout-panel>
    </div>
  </Layout>
</template>

<script>
import appConfig from "@src/app.config";
import Layout from "@layouts/main";

import FilterPanel from "@components/calendar/filter-panel";
import Tooltip from "@components/calendar/tooltip";

import contrastingColour from "@utils/color";

import { LocalGetSelf } from "@gql/user";
import { GetActivitiesByCompanyAndDates, UpdateActivityDates } from "@gql/activity";
import { GetChannels } from "@gql/tag";
import { GetReminders, UpdateReminderDates } from "@gql/reminder";
import { getActivityAssignmentGroups } from "@gql/group";

import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import ClickOutside from "vue-click-outside";
import { range, groupBy, flatMap, pull, debounce, orderBy } from "lodash";
import { format, startOfMonth, endOfMonth, subMonths, addMonths, startOfYear, parseISO } from "date-fns";

export default {
  page: {
    title: "Home",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    FullCalendar,
    FilterPanel,
    Tooltip,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      filter: {
        brief: {
          title: { contains: "", mode: "insensitive" },
        },
        startDate: {
          gte: format(startOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd"),
          lte: format(endOfMonth(addMonths(new Date(), 1)), "yyyy-MM-dd"),
        },
        status: {
          name: {
            in: ["approved", "delivered"],
          },
        },
      },
      currentMonth: {
        raw: startOfMonth(new Date()),
        name: format(startOfMonth(new Date()), "MMMM yyyy"),
        value: format(startOfMonth(new Date()), "yyyy-MM-dd"),
      },
      tooltipHideDelay: 300,
      tooltipLoading: false,
      debounced: null, // the hide tooltip function. It's here so we can canel it in case we highlight something else
      focusedBrief: null,
      focusedChannel: null,
      calendarApi: null,
      showMonthDropdown: false,
      filterPanelValues: null,
      tooltipShown: false,
      tooltipLeft: 0,
      tooltipTop: 0,
      activityInTooltip: {},
      filterPanelInstance: null, // the filter panel instance (to keep it in the dom when closed)
      isFilterDefault: 0,
    };
  },
  computed: {
    calendarOptions() {
      return {
        plugins: [dayGridPlugin, interactionPlugin, bootstrapPlugin],
        initialView: "dayGridMonth",

        headerToolbar: false,
        aspectRatio: 0.5,
        expandRows: true,
        contentHeight: "auto",

        weekends: true,
        firstDay: "1",
        weekNumbers: false,
        fixedWeekCount: false,
        showNonCurrentDates: true,
        displayEventTime: false,
        eventOrder: "reminder,textLength",
        themeSystem: "bootstrap",
        events: this.calendarEvents,
        editable: this.isCalendarEditable,

        eventMouseEnter: this.onCalendarMouseEnter,
        eventMouseLeave: this.onCalendarMouseLeave,
        eventClick: this.onCalendarClick,
        eventDrop: this.onCalendarEventDrop,
        eventResize: this.onCalendarEventDrop,
        eventDragStart: this.hideTooltip,
      };
    },
    calendarEvents() {
      let calendarEvents = [];

      // First put all of our activities into the events
      if (this.activities) {
        calendarEvents = this.activities.map((activity) => ({
          id: activity.id,
          start: format(parseISO(activity.startDate), "yyyy-MM-dd"),
          end: format(parseISO(activity.endDate), "yyyy-MM-dd"),
          title: activity.brief.isConfidential
            ? "Confidential"
            : ((activity.locations && activity.locations.length > 0) ? activity.locations.map(loc => loc.shortName).join(',') + ": " : "") +
              (activity.channel ? activity.channel.label + " - " : "") +
              activity.brief.title.slice(0, 160) +
              (activity.brief.title.length > 160 ? "..." : ""),
          classNames: [
            activity.brief.isConfidential ? "confidential" : "",
            !activity.channel ? "nochannel" : "",
            this.userCanEditActivity(activity) ? "userEditable" : "",
          ],
          color: activity.channel ? `${activity.channel.color}` : "#ccc",
          textColor: contrastingColour(activity.channel ? `${activity.channel.color}` : "#ccc"),
          extendedProps: { ...activity },
          textLength: activity.brief.title.length,
          grouped: activity.channel ? activity.channel.isGroupedOnCalendar : false,
          editable: this.userCanEditActivity(activity),
          height: 80,
        }));
      }

      // Then group any activities by channel if we need to
      // Iterate through every day by its activities
      const activitiesGroupedByStart = groupBy(calendarEvents, "start");
      Object.keys(activitiesGroupedByStart).forEach((dayKey) => {
        // And then by each channel
        const activitiesGroupedByDayAndChannel = groupBy(activitiesGroupedByStart[dayKey], "extendedProps.channel.id");
        Object.keys(activitiesGroupedByDayAndChannel).forEach((channelKey) => {
          const channel = activitiesGroupedByDayAndChannel[channelKey];
          // If there is more than one activity today for this channel and the channel is grouped on the calendar
          if (channel.length > 1 && channel[0].channel && channel[0].channel.isGroupedOnCalendar) {
            // Then add a grouped calendar event...
            calendarEvents.push({
              id: channel.id,
              start: channel[0].startDate,
              end: channel[0].startDate,
              title: channel[0].channel.label,
              classNames: ["multiple"],
              color: `${channel[0].channel.color}`,
              textColor: contrastingColour(channel[0].channel.color),
              extendedProps: channel,
              activityCount: channel.length,
              height: 80,
            });
            // And remove the ungrouped activities.
            channel.forEach((activity) => {
              calendarEvents = calendarEvents.filter((event) => {
                return event.id !== activity.id;
              });
            });
          }
        });
      });

      // Finally, add reminders
      calendarEvents = [
        ...calendarEvents,
        ...(this.reminders
          ? this.reminders.map((reminder) => ({
              id: reminder.id,
              start: format(parseISO(reminder.startDate), "yyyy-MM-dd"),
              end: format(parseISO(reminder.endDate), "yyyy-MM-dd"),
              title: reminder.text.length > 180 ? reminder.text.slice(0, 180) + "..." : reminder.text,
              classNames: ["reminder", reminder.blocking ? "blocking" : ""],
              extendedProps: { ...reminder },
              reminder: true, // Flag to order it first in the day
              textLength: reminder.text.length,
            }))
          : []),
      ];
      this.$log.debug("Events: ", calendarEvents);
      return calendarEvents;
    },
    monthList() {
      return range(12).map((index) => ({
        raw: addMonths(startOfYear(new Date()), index),
        name: format(addMonths(startOfYear(new Date()), index), "MMMM yyyy"),
        value: format(addMonths(startOfYear(new Date()), index), "yyyy-MM-dd"),
      }));
    },
    monthListValues() {
      return flatMap(this.monthList, "value");
    },
    stickyFilters() {
      return Number.parseInt(process.env.VUE_APP_CALENDAR_STICKY_FILTERS) === 1;
    },
    legendChannels() {
      return this.channels
        ? orderBy(
            this.channels.filter((c) => !c.hiddenInFilters),
            ["name"]
          )
        : [];
    },
    isCalendarEditable() {
      return this.user ? !this.user.isViewer : false;
    },
    scrollTop() {
      return 128 - document.body.scrollTop > 0 ? 128 - document.body.scrollTop : 0;
    },
  },
  apollo: {
    user: {
      query: LocalGetSelf,
      update: (data) => data.user,
    },
    activities: {
      query: GetActivitiesByCompanyAndDates,
      fetchPolicy: "no-cache",
      variables() {
        return {
          where: {
            ...this.formatDatesForApi(this.filter),
            company: {
              id: { equals: this.user.company.id },
            },

          },
        };
      },
      skip() {
        return !this.user;
      },
      update(data) {
        this.$log.debug("Got activity data from API", data);
        return data.activities;
      },
    },
    activityGroups: {
      query: getActivityAssignmentGroups,
      variables() {
        return {
          companyId: this.user.company.id,
        };
      },
      skip() {
        return !this.user;
      },
      update(data) {
        this.$log.debug("Got group data from API", data);
        return data.activityAssignmentGroups;
      },
    },
    channels: {
      query: GetChannels,
      variables() {
        return {
          companyId: this.user.company.id,
        };
      },
      skip() {
        return !this.user;
      },
      update(data) {
        this.$log.debug("Got channel data from API", data);
        return data.tags;
      },
      error(error) {
        this.$log.error("There was an error", error);
      },
    },
    reminders: {
      query: GetReminders,
      variables() {
        return {
          companyId: this.user.company.id,
          startDate: format(parseISO(this.filter.startDate.gte), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          endDate: format(parseISO(this.filter.startDate.lte), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        };
      },
      update(data) {
        this.$log.debug("Got reminder data from API", data);
        return data.reminders;
      },
      skip() {
        return !this.user;
      },
    },
  },
  watch: {
    user: {
      // This happens also on mount and ensures we don't call GetActivitiesByCompanyAndDates before we have a user
      handler(newVal) {
        // If we have filters from localstorage, apply them
        if (
          newVal &&
          newVal.company &&
          this.stickyFilters &&
          window.localStorage.getItem(process.env.VUE_APP_CALENDAR_FILTERS_LOCALSTORAGE_KEY)
        ) {
          const filters = JSON.parse(
            window.localStorage.getItem(process.env.VUE_APP_CALENDAR_FILTERS_LOCALSTORAGE_KEY)
          );
          delete filters.startDate;
          this.$log.debug("Calendar - Applying LocalStorage Filters", filters);
          this.$root.$emit("calendarFilterChange", filters);
        } else {
          this.$root.$emit("calendarFilterChange", this.filterPanelValues);
        }
      },
      deep: true,
    },
    activities: {
      handler(newVal) {
        if (newVal === undefined || newVal === null) {
          this.$log.debug("UNDEFINED - Calendar activities");
          this.jumpToMonth(this.currentMonth);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$log.debug("Here1")
    try {
      this.calendarApi = this.$refs.fullCalendar.getApi();
    } catch (err) {
      this.$log.error(err)
    }
    // Gain access to fullcalendar API object
    // https://fullcalendar.io/docs/vue - Accessing FullCalendar’s API section
    this.$log.debug("Here1.5")
    this.$root.$on("calendarFilterChange", this.onCalendarFilterChange);
    this.$root.$on("filterDefault", this.filterDefault);
    this.$log.debug("Here2")
    this.filterPanelInstance = this.$showPanel({
      component: FilterPanel,
      openOn: "left",
      disableBgClick: false,
      cssClass: "filterPanel",
      width: 325,
      keepAlive: true,
      props: {
        parentFilter: this.filter,
      },
    });
    this.filterPanelInstance.hide();
    // If we have dates saved in LocalStorage, jump to those
    const savedDates = null; // This feature is disabled for now
    /* const savedDates = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_CALENDAR_DATES_LOCALSTORAGE_KEY));
    if (savedDates && savedDates.start) {
      this.currentMonth = {
        raw: startOfMonth(parse(savedDates.start, "yyyy-MM-dd", new Date(2020, 0, 1))),
        name: format(startOfMonth(parse(savedDates.start, "yyyy-MM-dd", new Date(2020, 0, 1))), "MMMM yyyy"),
        value: format(startOfMonth(parse(savedDates.start, "yyyy-MM-dd", new Date(2020, 0, 1))), "yyyy-MM-dd"),
      };
      this.$log.debug("Jumping to SAVED date", savedDates, this.currentMonth);
      this.jumpToMonth(this.currentMonth);
    } */
    // Otherwise go to the current month
    this.$log.debug("Jumping CURRENT date", savedDates, this.currentMonth);
    this.jumpToMonth(this.currentMonth);
  },
  methods: {
    /* Date navigation */
    async jumpToMonth(month) {
      const m = month.target || month;
      const curMonth = {
        raw: parseISO(m.value),
        name: format(parseISO(m.value), "MMMM yyyy"),
        value: format(parseISO(m.value), "yyyy-MM-dd"),
      };
      const startDate = format(startOfMonth(subMonths(new Date(curMonth.raw.getTime()), 1)), "yyyy-MM-dd");
      const endDate = format(endOfMonth(addMonths(new Date(curMonth.raw.getTime()), 1)), "yyyy-MM-dd");

      // Set filters
      this.filter.startDate.gte = startDate;
      this.filter.startDate.lte = endDate;

      // Save start and end date in localstorage (for coming back to the same dates after page change)
      this.$log.debug(
        `jumpToMonth - Saving into LocalStorage (${process.env.VUE_APP_CALENDAR_DATES_LOCALSTORAGE_KEY})`
      );
      window.localStorage.setItem(
        process.env.VUE_APP_CALENDAR_DATES_LOCALSTORAGE_KEY || "cdDates",
        JSON.stringify({ start: curMonth.value })
      );

      // Load events for the specified month
      if (this.filterPanelValues) {
        this.filterPanelValues.startDate = this.filter.startDate;
        this.onCalendarFilterChange(this.filterPanelValues);
      } else {
        this.$apollo.queries.activities.setVariables({ where: this.formatDatesForApi(this.filter) });
        this.$apollo.queries.activities.refetch();
      }
      await this.$apollo.queries.reminders.refresh();
      this.currentMonth = curMonth;
      // Move calendar page
      if (!this.calendarApi) {
        this.calendarApi = this.$refs.fullCalendar.getApi();
      }
      this.calendarApi.gotoDate(m.value);
    },
    goToPreviousMonth() {
      const previousMonth = {
        raw: subMonths(new Date(this.currentMonth.raw.getTime()), 1),
        name: format(subMonths(new Date(this.currentMonth.raw.getTime()), 1), "MMMM yyyy"),
        value: format(subMonths(new Date(this.currentMonth.raw.getTime()), 1), "yyyy-MM-dd"),
      };
      this.currentMonth = previousMonth;
      this.jumpToMonth(this.currentMonth);
    },
    goToNextMonth() {
      const nextMonth = {
        raw: addMonths(new Date(this.currentMonth.raw.getTime()), 1),
        name: format(addMonths(new Date(this.currentMonth.raw.getTime()), 1), "MMMM yyyy"),
        value: format(addMonths(new Date(this.currentMonth.raw.getTime()), 1), "yyyy-MM-dd"),
      };
      this.currentMonth = nextMonth;
      this.jumpToMonth(this.currentMonth);
    },
    gotoToday() {
      // calculate previous month
      const todayMonth = {
        raw: startOfMonth(new Date()),
        name: format(startOfMonth(new Date()), "MMMM yyyy"),
        value: format(startOfMonth(new Date()), "yyyy-MM-dd"),
      };
      this.currentMonth = todayMonth;
      // Update filter object
      this.filter.startDate.gte = format(startOfMonth(subMonths(new Date(this.currentMonth.raw), 1)), "yyyy-MM-dd");
      this.filter.startDate.lte = format(endOfMonth(addMonths(new Date(this.currentMonth.raw), 1)), "yyyy-MM-dd");
      this.jumpToMonth(this.currentMonth);
    },

    /* Permissions */
    userCanEditActivity(activity) {
      // Admins can always edit
      if (this.user.isAdminUser) {
        return true;
      }

      // Confidential activities can't be edited by non-admin
      if (activity.brief.isConfidential && !this.user.isAdminUser) {
        return false;
      }

      // Otherwise, compile a list of users who can edit this
      const users = [];

      // Activity owners are a candidate
      if (activity.owner) {
        users.push(activity.owner);
      }

      // If the activity is assigned to groups, then their members are candidates
      const groups = this.activityGroups.filter((candidateGroup) =>
        candidateGroup.activities.find((candidateGroupActivity) => candidateGroupActivity.id === activity.id)
      );
      if (groups.length > 0) {
        groups.forEach((group) => {
          group.users.forEach((userLink) => {
            userLink.user.isApprover = Boolean(userLink.isApprover);
            // If the user is an approver for this group or any other group this
            // activity is assigned to, then they are able to approve this activity
            const existingUser = find(users, { id: userLink.user.id });
            if (existingUser) {
              // If they already exist, ensure that they are an approver if necessary
              if (userLink.user.isApprover) {
                existingUser.isApprover = true;
              }
            } else {
              users.push(userLink.user);
            }
          });
        });
      }

      // Remove duplicates and see if the current user is a candidate
      flatMap(users).forEach((user) => {
        if (user.id === this.user.id) {
          return true;
        }
      });
      return false;
    },

    /* Tooltips */
    showTooltip(activity) {
      // Multiple tooltips
      if (activity.activityCount) {
        this.activityInTooltip = activity;
        this.tooltipShown = true;
        return false;
      }
      // Do not show tooltip for confidential (TODO: Change so user allowed can see it)
      if ((!activity.extendedProps || !activity.extendedProps.brief) && (!activity || !activity.brief)) {
        this.tooltipShown = false;
        return false;
      }
      this.activityInTooltip = activity;
      this.tooltipShown = true;
    },
    hideTooltip() {
      this.activityInTooltip = null;
      this.tooltipShown = false;
    },
    cancelHideTooltip() {
      if (this.debounced) {
        this.debounced.cancel();
      }
    },
    hideMonthDropdown() {
      this.showMonthDropdown = false;
    },

    /* Highlighting */
    highlightBriefActivities(brief) {
      this._highlightLowlightBrief(brief, "highlight", false);
    },
    lowlightBriefActivities(brief) {
      this._highlightLowlightBrief(brief, "lowlight", false);
    },
    showOnlyBriefActivities(brief) {
      // Show only activities of a certain brief
      this._highlightLowlightBrief(brief, "highlight", true);
    },
    resetLowHighlight(ignoreFull = false) {
      this.focusedBrief = null;
      this.focusedChannel = null;
      this.activities.forEach((activity) => {
        if (!this.calendarApi) {
          this.calendarApi = this.$refs.fullCalendar.getApi();
        }
        const event = this.calendarApi.getEventById(activity.id);
        if (event) {
          // toggle lowlighted class
          event.setProp("classNames", [...pull(event.classNames, "lowlighted")]);
          if (!ignoreFull) {
            event.setProp("classNames", [...pull(event.classNames, "lowlighted-plus")]);
          }
        }
      });
    },
    _highlightLowlightBrief(brief, action = "highlight", showOnly = false) {
      this.$log.debug("HighlightLowlightBrief: ", brief, action, showOnly);
      if (!brief) {
        return false;
      }
      if (!this.calendarApi) {
        this.calendarApi = this.$refs.fullCalendar.getApi();
      }
      this.tooltipLoading = true;
      const briefId = brief.id;

      // Save the brief and the action in the highlighted brief element
      // (Only if different, if it's the same brief and action, unset it)
      this.focusedBrief =
        !this.focusedBrief || this.focusedBrief.id !== briefId || this.focusedBrief.action !== action
          ? { id: briefId, action: action }
          : null;

      // Get the activities to action
      let briefActivities;
      let otherActivities;
      if (action === "highlight") {
        briefActivities = this.activities.filter((activity) => activity.brief.id !== briefId);
        otherActivities = this.activities.filter((activity) => activity.brief.id === briefId);
      } else {
        briefActivities = this.activities.filter((activity) => activity.brief.id === briefId);
        otherActivities = this.activities.filter((activity) => activity.brief.id !== briefId);
      }
      const classesToAdd = showOnly === true ? ["lowlighted", "lowlighted-plus"] : ["lowlighted"];

      // Loop activities
      briefActivities.forEach((activity) => {
        const event = this.calendarApi.getEventById(activity.id);
        if (event) {
          // toggle lowlighted class
          event.classNames.includes("lowlighted")
            ? event.setProp("classNames", [...pull(event.classNames, ...classesToAdd)])
            : event.setProp("classNames", [...event.classNames, ...classesToAdd]);
        }
      });
      otherActivities.forEach((activity) => {
        const event = this.calendarApi.getEventById(activity.id);
        if (event) {
          // toggle lowlighted class
          event.setProp("classNames", [...pull(event.classNames, ...classesToAdd)]);
        }
      });
      this.tooltipLoading = false;
    },
    highlightChannel(channel, showOnly = false) {
      this.$log.debug("Highlight Channel: ", channel, showOnly);
      if (!channel) {
        return false;
      }
      if (!this.calendarApi) {
        this.calendarApi = this.$refs.fullCalendar.getApi();
      }
      this.tooltipLoading = true;
      const channelId = channel.id;

      this.focusedChannel = channel

      // Get the activities to action
      const channelActivities = this.activities.filter((activity) => activity.channel && activity.channel.id !== channelId);
      const otherActivities = this.activities.filter((activity) => activity.channel && activity.channel.id === channelId);
      const classesToAdd = showOnly === true ? ["lowlighted", "lowlighted-plus"] : ["lowlighted"];

      // Loop activities
      channelActivities.forEach((activity) => {
        const event = this.calendarApi.getEventById(activity.id);
        if (event) {
          // toggle lowlighted class
          event.classNames.includes("lowlighted")
            ? event.setProp("classNames", [...pull(event.classNames, ...classesToAdd)])
            : event.setProp("classNames", [...event.classNames, ...classesToAdd]);
        }
      });
      otherActivities.forEach((activity) => {
        const event = this.calendarApi.getEventById(activity.id);
        if (event) {
          // toggle lowlighted class
          event.setProp("classNames", [...pull(event.classNames, ...classesToAdd)]);
        }
      });
      this.tooltipLoading = false;
    },

    /* Filter */
    resetFilter() {
      // Reset panel filters (the panel then resets the calendar)
      this.filter.brief.title_contains = "";
      this.filterPanelValues = null;
      this.$root.$emit("resetPanelFilters");

      this.resetLowHighlight();
    },
    showFilterPanel() {
      if (this.filterPanelInstance) {
        this.filterPanelInstance.show();
        return;
      }
      this.filterPanelInstance.promise.then((result) => {});
    },
    filterTitle() {
      this.$root.$emit("calendarFilterChange", this.filter);
    },
    onCalendarFilterChange(filterPanelValues) {
      // Capture the changes and filter the calendar
      this.$log.debug("Calendar - onCalendarFilterChange - Filter Panel values", { ...filterPanelValues });

      // save filter panel values
      this.filterPanelValues = filterPanelValues;
      if (!this.user && !this.filter.company) {
        this.$log.debug("onCalendarFilterChange - NO COMPANY, skipping", {
          filterPanelValues: this.filterPanelValues,
          filter: this.filter,
          user: this.user,
        });
        return false;
      }

      // Define defaults and apply them for now
      const defaultVariables = {
        company: { id: { equals: this.user ? this.user.company.id : this.filter.company.id } },
        startDate: {
          gte: this.filter.startDate.gte,
          lte: this.filter.startDate.lte,
        },
        brief: {
          title: {
            contains: this.filter.brief.title.contains,
            mode: "insensitive",
          },
        },
        status: {
          name: {
            in: ["approved", "delivered"],
          },
        },
      };
      const apolloFilters = { ...defaultVariables };
      if (this.$apollo.queries.activities && !this.$apollo.queries.activities.loading) {
        this.$apollo.queries.activities.setVariables({ where: this.formatDatesForApi(apolloFilters) });
      }

      // Walk the filter panel and populate the actual filter
      // const dynamicPropertyWhere = {}; // Unimplemented
      const isExternal = { external: false, internal: false };
      if (filterPanelValues) {
        Object.keys(filterPanelValues).forEach((key) => {
          const filterPanelValue = filterPanelValues[key];
          switch (key) {
            case "tags": {
              const tagIds = filterPanelValue.map((tag) => tag.id);
              if (tagIds.length > 0) apolloFilters.brief.tags = { some: { id: { in: tagIds } } };
              break;
            }
            case "locations": {
              const tagIds = filterPanelValue.map((tag) => tag.id);
              if (tagIds.length > 0) apolloFilters.locations = { some: { id: { in: tagIds } } };
              break;
            }
            case "spokespersons": {
              const spokespersonIds = filterPanelValue.map((spokesperson) => spokesperson.id);
              if (spokespersonIds.length > 0) apolloFilters.spokespersons = { some: { id: { in: spokespersonIds } } };
              break;
            }
            case "channels": {
              const channelIds = filterPanelValue.map((channel) => channel.id);
              if (channelIds.length > 0) apolloFilters.channel = { id: { in: channelIds } };
              break;
            }
            case "activityStatuses": {
              const activityStatuses = filterPanelValue.map((status) => status.id);
              if (activityStatuses.length > 0) apolloFilters.status = { id: { in: activityStatuses } };
              break;
            }
            case "isExternal": {
              if (filterPanelValue === true) isExternal.external = true;
              break;
            }
            case "isInternal": {
              if (filterPanelValue === true) isExternal.internal = true;
              break;
            }
            case "owners": {
              const owners = filterPanelValue.map((owner) => owner.id);
              if (owners.length > 0) apolloFilters.owner = { id: { in: owners } };
              break;
            }
            default: {
              // These are all the brief properties. This is untested and unimplemented
              /*
              const dynamicPropertyIds = dynamicPropertyWhere.properties.some
                ? dynamicPropertyWhere.properties.some.multipleValues.some.id.in
                : [];
              filterPanelValue.forEach((s) => {
                dynamicPropertyIds.push(s.id);
              });
              if (dynamicPropertyIds.length > 0)
                apolloFilters.brief.properties.some = dynamicPropertyWhere.properties.some = {
                  multipleValues: {
                    some: {
                      id: {
                        in: dynamicPropertyIds,
                      },
                    },
                  },
                };
                */
              break;
            }
          }
        });
      }

      // Handle internal/external filtering
      if (isExternal.external === isExternal.internal) {
        // If both or none are checked, no need to include the filter
        delete apolloFilters.isExternal;
      } else if (isExternal.external && !isExternal.internal) {
        apolloFilters.isExternal = true;
      } else {
        apolloFilters.isExternal = false;
      }

      this.$log.debug("Calendar - Filtering", apolloFilters);
      // Update variables and execute query
      if (this.$apollo.queries.activities && !this.$apollo.queries.activities.loading) {
        this.$apollo.queries.activities.setVariables({ where: this.formatDatesForApi(apolloFilters) });
        this.$apollo.queries.activities.refetch();
      }
    },

    /* Calendar Events */
    onCalendarMouseEnter(params) {
      // this.$log.debug("CalendarHover", params);
      // If it's a reminder we do nothing
      if (params.event.extendedProps.reminder) {
        return false;
      }
      // parse activity
      const activity = params.event.extendedProps;
      const elementBound = params.el.getBoundingClientRect();
      // position tooltip on item
      this.tooltipLeft = elementBound.left - 20;
      this.tooltipTop = elementBound.top + window.scrollY + 20;
      // Cancel hide tooltip function
      if (this.debounced) {
        this.debounced.cancel();
      }
      // Show tooltip
      this.showTooltip(activity);
    },
    onCalendarMouseLeave(params) {
      this.debounced = debounce(function () {
        this.$log.debug("Hiding Tooltip");
        this.hideTooltip();
      }, this.tooltipHideDelay);
      this.debounced();
    },
    filterDefault(event) {
      this.isFilterDefault = event;
    },
    debouncedFilterTitle() {
      debounce(this.filterTitle, 300);
    },
    onCalendarClick(params) {
      if (params.event.extendedProps && params.event.extendedProps.brief.isConfidential && !this.user.isAdminUser) {
        return false;
      }
      this.$router.push({ name: "briefs.edit", params: { id: params.event.extendedProps.brief.id } });
    },
    async onCalendarEventDrop(params) {
      const eventId = params.event.id;
      const eventType = params.event.extendedProps.__typename;
      // If event type is not defined we return an error and don't call the API
      if (!eventType || !eventId) {
        this.$log.error(`onCalendarEventDrop - Event type or ID for event undefined`, params.event);
      }
      const newDates = {
        startDate: format(parseISO(params.event.start.toISOString()), "yyyy-MM-dd"),
        endDate: params.event.end
          ? format(parseISO(params.event.end.toISOString()), "yyyy-MM-dd")
          : format(parseISO(params.event.start.toISOString()), "yyyy-MM-dd"),
      };
      this.$log.debug(`onCalendarEventDrop - Updating event (${eventType}) ${eventId}`, newDates);

      // Select correct apollo mutation based on event type (reminder or activity)
      const mutation = eventType === "Reminder" ? UpdateReminderDates : UpdateActivityDates;
      return this.$apollo
        .mutate({
          mutation: mutation,
          variables: {
            id: eventId,
            startDate: format(parseISO(newDates.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            endDate: format(parseISO(newDates.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          },
        })
        .then((data) => {
          // Result
          this.$log.debug(`onCalendarEventDrop - Updated event (${eventType}) ${eventId}`, data);
        })
        .catch((err) => {
          // Result
          this.$log.error(`onCalendarEventDrop - Error updating event (${eventType}) ${eventId}`, err);
        });
    },

    printPage() {
      document.querySelector("#navbar").classList.add("d-none");
      document.querySelector("#calendartopbar").classList.add("d-none");
      document.querySelector("#todayButton").classList.add("d-none");
      document.querySelector("#navButtons").classList.add("d-none");
      document.querySelector("#printButton").classList.add("d-none");
      document.querySelector("#printheader").classList.remove("d-none");
      window.print();
      document.querySelector("#navbar").classList.remove("d-none");
      document.querySelector("#calendartopbar").classList.remove("d-none");
      document.querySelector("#todayButton").classList.remove("d-none");
      document.querySelector("#navButtons").classList.remove("d-none");
      document.querySelector("#printButton").classList.remove("d-none");
      document.querySelector("#printheader").classList.add("d-none");
    },
    formatDatesForApi(filter) {
      if (!filter) return undefined;
      // this.$log.debug("Formatting Dates for ", filter);
      // Replace dates with ISO strings for the API
      // NOTE: for some reason the date string is turning into ISO, so use parseISO which can
      // handle both ISO date and ISO datetime (datetime being what the API needs)
      const isoFilter = filter;
      if (filter.startDate) {
        Object.keys(filter.startDate).forEach((key) => {
          isoFilter.startDate[key] = (
            " " + format(parseISO(filter.startDate[key]), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
          ).slice(1);
        });
      }
      if (filter.endDate) {
        Object.keys(filter.endDate).forEach((key) => {
          isoFilter.endDate[key] = (" " + format(parseISO(filter.endDate[key]), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")).slice(
            1
          );
        });
      }
      if (filter.createdAt) {
        Object.keys(filter.createdAt).forEach((key) => {
          isoFilter.createdAt[key] = (
            " " + format(parseISO(filter.createdAt[key]), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
          ).slice(1);
        });
      }
      if (filter.updatedAt) {
        Object.keys(filter.updatedAt).forEach((key) => {
          isoFilter.updatedAt[key] = (
            " " + format(parseISO(filter.updatedAt[key]), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
          ).slice(1);
        });
      }
      // this.$log.debug("Formatting Dates result: ", JSON.stringify(isoFilter));
      return isoFilter;
    },
  },
};
</script>
